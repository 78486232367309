'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Footer = function Footer() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $logoImg = document.querySelector('.site-footer .footer-logo img');
  var $centerCopy = document.querySelector('.site-footer .footer-branch');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    switchLogo();

    window.addEventListener('resize', switchLogo);

    if ($centerCopy) {
      var heightCenterCopy = $centerCopy.clientHeight;
      var rightFooter = document.querySelector('.site-footer .footer-right');

      if (heightCenterCopy <= 18) {
        rightFooter.style.padding = '58px 40px 40px';
      }

      console.log(heightCenterCopy);
    }

    var $closeNewslettersButton = document.querySelector('[action-close-newsletters]');
    var $openNewslettersButton = document.querySelector('[action-open-newsletters]');
    var $popupNewslettersBox = document.querySelector('[action-popup-newsletters]');

    if ($openNewslettersButton) {
      $openNewslettersButton.addEventListener('click', function (e) {
        e.preventDefault();
        $popupNewslettersBox.classList.add('is-visible');
      });

      $closeNewslettersButton.addEventListener('click', function (e) {
        e.preventDefault();
        $popupNewslettersBox.classList.remove('is-visible');
      });
    }

    inited = true;

    return true;
  };

  var switchLogo = function switchLogo() {
    if ($logoImg) {
      var $logoImgDark = $logoImg.getAttribute('data-footer-logo-mobile');
      var $logoImgLight = $logoImg.getAttribute('data-footer-logo');

      if (window.innerWidth <= 767) {
        $logoImg.src = $logoImgDark;
      } else {
        $logoImg.src = $logoImgLight;
      }
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();