'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.SubNav = function SubNav() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $subNav = document.querySelector('[data-sub-nav]');
  var $nav = document.querySelector('#main-header');
  var st = 0;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($subNav) {
      activateSubNavFollow();
    }

    return true;
  };

  var activateSubNavFollow = function activateSubNavFollow() {
    // const buffer = 10;

    // let lastScrollTop = 0;

    st = document.documentElement.scrollTop;
    var $zoneTop = $subNav.offsetTop - $nav.offsetHeight;

    console.log(st);

    if (st >= $zoneTop) {
      $subNav.classList.add('is-sticky');
      $subNav.style.transform = 'translateY(' + $nav.offsetHeight + 'px)';
    } else {
      $subNav.classList.remove('is-sticky');
      $subNav.style.transform = 'translateY(0)';
    }

    window.addEventListener('scroll', function () {
      st = document.documentElement.scrollTop;

      console.log(st);

      if (st >= $zoneTop) {
        $subNav.classList.add('is-sticky');
        $subNav.style.transform = 'translateY(' + $nav.offsetHeight + 'px)';
      } else {
        $subNav.classList.remove('is-sticky');
        $subNav.style.transform = 'translateY(0)';
      }

      // if (st > lastScrollTop) {
      //   $subNav.style.transform = 'translateY(0)';
      // } else if (st < (lastScrollTop - buffer)) {
      //   $subNav.style.transform = `translateY(${$nav.offsetHeight}px)`;
      // }
      //
      // if (st <= 15) {
      //   $subNav.style.transform = 'translateY(0)';
      // }

      // lastScrollTop = st;
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();