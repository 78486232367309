'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Blocks = function Blocks() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $blocks = [].slice.call(document.querySelectorAll('.site-block'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($blocks) {
      console.log($blocks.length);

      $blocks.forEach(function (block, index) {
        console.log(block, index, $blocks[index + 1]);
        if (block.classList.contains('block-pricing') && $blocks[index + 1] && $blocks[index + 1].classList.contains('block-cta')) {
          block.classList.add('is-before-cta');
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();