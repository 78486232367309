'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.ScrollTo = function ScrollTo() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));

    $scrollLinks.forEach(function (item) {
      item.addEventListener('click', function (e) {
        e.preventDefault();

        if (item.classList.contains('nav-item')) {
          var $activeItem = document.querySelector('.nav-item.is-active');

          if ($activeItem && item !== $activeItem) $activeItem.classList.remove('is-active');

          item.classList.add('is-active');
        }

        var selector = item.getAttribute('data-scroll-to');

        var $zone = document.querySelector(selector);

        var topPos = $zone.getBoundingClientRect().top + window.scrollY;

        window.scroll({
          top: topPos,
          behavior: 'smooth'
        });
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();