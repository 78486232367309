'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Forms = function Forms() {
  'use strict';

  /**
   * DOM elements
   * @private
   */

  var $fieldsFile = [].slice.call(document.querySelectorAll('.field-file'));
  var $validateForms = [].slice.call(document.querySelectorAll('[data-form-validate]'));
  var $iMaskFields = [].slice.call(document.querySelectorAll('[data-imask]'));

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Configs
   * @private
   */
  var locale = '';

  /**
   * Validation messages
   * @private
   */
  var validationMessages = {
    fr: {
      isDefault: 'Le champ est invalide',
      isRequired: 'Le champ est requis',
      isEmail: 'L’adresse utilisée n’est pas règlementaire'
    },
    en: {
      isDefault: 'The field is invalid',
      isRequired: 'The field is required',
      isEmail: 'The address used is not regulatory'
    }
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    locale = site.App.config('locale');

    setIMasks();

    setFileFields();

    setValidations();

    return true;
  };

  var setIMasks = function setIMasks() {
    if ($iMaskFields) {
      $iMaskFields.forEach(function (field) {
        var elementType = field.getAttribute('data-imask-type');
        var maskOptions = {};
        var mask = null;

        if (elementType) {
          if (elementType === 'phone') {
            maskOptions = {
              mask: '000 000 0000'
            };
            mask = IMask(field, maskOptions);
            field.placeholder = maskOptions.mask.replace(/0/g, 'X');
          }
        }
      });
    }

    return true;
  };

  var setFileFields = function setFileFields() {
    if ($fieldsFile) {
      $fieldsFile.forEach(function (field) {
        var $input = field.querySelector('input');
        var $btn = field.querySelector('.site-btn');
        var $text = field.querySelector('.regular-text');

        $btn.addEventListener('click', function (e) {
          e.preventDefault();

          $input.click();
        });

        $input.addEventListener('change', function (e) {
          var fileList = e.target.files;

          $text.textContent = fileList[0].name;
        }, false);
      });
    }
  };

  var setValidations = function setValidations() {
    if ($validateForms) {
      $validateForms.forEach(function (form) {
        var $inputs = [].slice.call(form.querySelectorAll('input[name]:not([type="hidden"]), textarea[name]'));

        if ($inputs) {
          $inputs.forEach(function (input) {
            switch (input.tagName) {
              case 'INPUT':
                input.addEventListener('input', function () {
                  checkInputValidation(input);
                }, false);
                break;
              case 'TEXTAREA':
                input.addEventListener('input', function () {
                  checkInputValidation(input);
                }, false);
                break;
              case 'SELECT':
                input.addEventListener('change', function () {
                  checkInputValidation(input);
                }, false);
                break;
              default:
                input.addEventListener('input', function () {
                  checkInputValidation(input);
                }, false);
            }
          });

          form.addEventListener('submit', function (e) {
            if (!validateForm($inputs)) {
              e.preventDefault();
            }
          });
        }
      });
    }
  };

  var validateForm = function validateForm(inputs) {
    var $formValidations = [];

    inputs.forEach(function (input) {
      $formValidations.push(checkInputValidation(input));
    });

    var formErrors = $formValidations.reduce(function (results, current) {
      if (!current) results.push(current);

      return results;
    }, []);

    return !(formErrors.length > 0);
  };

  var checkInputValidation = function checkInputValidation(input) {
    var parentElement = input.closest('.form-field-group');
    var wrapperElement = input.closest('.field-input-wrapper') ? input.closest('.field-input-wrapper') : input.closest('.field-file');

    var errorElement = parentElement.querySelector('.field-error');
    var isValid = false;

    if (input.hasAttribute('required') && validator.isEmpty(input.value)) {
      isValid = false;
      setErrorField(errorElement, wrapperElement, isValid, getValidationTranslation('required'));
    } else if (input.getAttribute('type') === 'email' && !validator.isEmail(input.value)) {
      isValid = false;
      setErrorField(errorElement, wrapperElement, isValid, getValidationTranslation('email'));
    } else {
      isValid = true;
      setErrorField(errorElement, wrapperElement, isValid, '');
    }

    return isValid;
  };

  var getValidationTranslation = function getValidationTranslation(validationType) {
    switch (validationType) {
      case 'required':
        return locale === 'fr' ? validationMessages.fr.isRequired : validationMessages.en.isRequired;
      case 'email':
        return locale === 'fr' ? validationMessages.fr.isEmail : validationMessages.en.isEmail;
      default:
        return locale === 'fr' ? validationMessages.fr.isDefault : validationMessages.en.isDefault;
    }
  };

  var setErrorField = function setErrorField(errorElement, wrapperElement, isValid, message) {
    if (!isValid) {
      wrapperElement.classList.remove('has-success');
      wrapperElement.classList.add('has-error');
      errorElement.classList.add('is-active');
    } else {
      wrapperElement.classList.add('has-success');
      wrapperElement.classList.remove('has-error');
      errorElement.classList.remove('is-active');
    }

    setErrorText(errorElement, message);
  };

  var setErrorText = function setErrorText(errorElement, text) {
    errorElement.textContent = text;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();