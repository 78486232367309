'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.SliderNav = function SliderNav() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliderArrows = [].slice.call(document.querySelectorAll('[data-slider-nav]'));

    if ($sliderArrows) {
      $sliderArrows.forEach(function (arrows) {
        var $arrowLeft = document.querySelector('[data-slider-nav="' + arrows.dataset.sliderNav + '"] .site-btn-arrow.left');
        var $arrowRight = document.querySelector('[data-slider-nav="' + arrows.dataset.sliderNav + '"] .site-btn-arrow.right');
        var $sliderlides = document.querySelector('[data-slider-slides="' + arrows.dataset.sliderNav + '"]');

        $arrowLeft.addEventListener('click', function (e) {
          e.preventDefault();

          if ($sliderlides.scrollLeft > 0) {
            var $slideCard = $sliderlides.querySelector('.activity-card');
            var leftPos = $sliderlides.scrollLeft - $slideCard.clientWidth;

            $sliderlides.scroll({
              top: 0,
              left: leftPos,
              behavior: 'smooth'
            });
          }
        });

        $arrowRight.addEventListener('click', function (e) {
          e.preventDefault();

          var maxScrollLeft = $sliderlides.scrollWidth - $sliderlides.offsetWidth;

          if ($sliderlides.scrollLeft <= maxScrollLeft) {
            var $slideCard = $sliderlides.querySelector('.activity-card');
            var rightPos = $sliderlides.scrollLeft + $slideCard.offsetWidth;

            $sliderlides.scroll({
              top: 0,
              left: rightPos,
              behavior: 'smooth'
            });
          }
        });
      });
    }

    var $calendarArrows = [].slice.call(document.querySelectorAll('[data-calendar-nav]'));

    if ($calendarArrows) {
      $calendarArrows.forEach(function (arrows) {
        var $arrowLeft = arrows.querySelector('[data-calendar-nav] .site-btn-arrow.left');
        var $arrowRight = arrows.querySelector('[data-calendar-nav] .site-btn-arrow.right');
        var $calendarElement = document.getElementById('' + arrows.dataset.calendarNav);
        var $calendarContainer = $calendarElement.querySelector('.calendar-container');

        $arrowLeft.addEventListener('click', function (e) {
          e.preventDefault();

          if ($calendarContainer.scrollLeft > 0) {
            var $calendarLabel = $calendarContainer.querySelector('.calendar-label');
            var leftPos = $calendarContainer.scrollLeft - $calendarLabel.offsetWidth * 2;

            $calendarContainer.scroll({
              top: 0,
              left: leftPos,
              behavior: 'smooth'
            });
          }
        });

        $arrowRight.addEventListener('click', function (e) {
          e.preventDefault();

          var maxScrollLeft = $calendarContainer.scrollWidth - $calendarContainer.offsetWidth;

          if ($calendarContainer.scrollLeft <= maxScrollLeft) {
            var $calendarLabel = $calendarContainer.querySelector('.calendar-label');
            var rightPos = $calendarContainer.scrollLeft + $calendarLabel.offsetWidth * 2;

            $calendarContainer.scroll({
              top: 0,
              left: rightPos,
              behavior: 'smooth'
            });
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();