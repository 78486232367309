'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Centers = function Centers() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $body = document.querySelector('body');
  var $centerSwitcherLabel = document.querySelector('[data-center-switch-label]');
  var triggerSelects = [].slice.call(document.querySelectorAll('[data-center-switch]'));
  var triggerCenterSwitchLink = [].slice.call(document.querySelectorAll('[data-center-switch-link]'));
  var triggerFullscreen = [].slice.call(document.querySelectorAll('[data-center-trigger]'));
  var overlayFullscreen = document.querySelector('.fullscreen-overlay');
  var contentFullscreen = document.querySelector('.fullscreen-container');
  var closeFullscreen = document.querySelector('.fullscreen-close');
  var s = new Snap('.fullscreen-overlay');
  var els = s.selectAll('.animate');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (triggerCenterSwitchLink) {
      triggerCenterSwitchLink.forEach(function (link) {
        link.addEventListener('click', function () {
          var centerSlug = link.getAttribute('data-center-switch-link');

          Cookies.set('center', centerSlug, { expires: 30 });

          window.location.href = link.getAttribute('href');
        });
      });
    }

    if (triggerSelects && $centerSwitcherLabel) {
      var parentContainer = $centerSwitcherLabel.closest('.center-switcher-container');
      var optionsContainer = parentContainer.querySelector('.center-switcher-options');

      // if (Cookies.get('center')) {
      //   const currentCenterLink = optionsContainer.querySelector(`a[href="#${Cookies.get('center')}"]`);
      //   console.log(currentCenterLink);
      //   $centerSwitcherLabel.textContent = currentCenterLink.textContent;
      // }

      parentContainer.addEventListener('click', function (e) {
        e.preventDefault();

        if (optionsContainer) {
          optionsContainer.classList.toggle('is-active');
        }
      });

      triggerSelects.forEach(function (selectLink) {
        selectLink.addEventListener('click', function (e) {
          e.preventDefault();

          var newCenterSlug = selectLink.getAttribute('href').replace('#', '');

          Cookies.set('center', newCenterSlug, { expires: 30 });

          var lastSegments = window.location.href.replace(window.location.origin, '');
          var countrySegment = lastSegments.trim().split('/');

          var countrySegments = countrySegment.filter(function (element) {
            return element !== '';
          });

          if (countrySegments[0] === 'en') {
            countrySegment = countrySegments[1];
          } else {
            countrySegment = countrySegments[0];
          }
          console.log(countrySegment);

          window.location.href = window.location.href.replace(countrySegment, newCenterSlug);
        });
      });
    }

    if (triggerFullscreen) {
      triggerFullscreen.forEach(function (trigger) {
        trigger.addEventListener('click', function (e) {
          e.preventDefault();

          if (overlayFullscreen) {
            var position = 0;

            if (window.innerWidth <= 767) {
              position = getCenterPosition(e.target);

              overlayFullscreen.style.bottom = position.y + 'px';
            } else {
              position = getMousePosition(e);

              overlayFullscreen.style.top = position.y + 'px';
            }

            overlayFullscreen.style.left = position.x + 'px';

            $body.classList.add('not-scrollable');
            overlayFullscreen.classList.add('is-active');
            contentFullscreen.style.zIndex = 100;

            setTimeout(function () {
              contentFullscreen.classList.add('is-active');
            }, 250);

            var ellipse = overlayFullscreen.querySelector('ellipse');

            if (window.innerWidth <= 767) {
              var xStart = 49;
              var xEnd = 70;

              if (window.innerHeight <= 899) {
                ellipse.setAttribute('ry', '55');
                xStart = 48;
                xEnd = 55;
              }

              if (window.innerHeight <= 799) {
                xStart = 45;
                xEnd = 55;
              }

              setTimeout(function () {
                elasticAnimation(xStart, xEnd);
              }, 1100);
            }

            closeFullscreen.addEventListener('click', function (e) {
              e.preventDefault();

              overlayFullscreen.classList.remove('is-active');
              contentFullscreen.classList.remove('is-active');
              contentFullscreen.style.zIndex = 0;

              var $galleries = [].slice.call(document.querySelectorAll('[data-gallery]'));

              if ($galleries.length === 0) $body.classList.remove('not-scrollable');
            });
          }
        });
      });
    }

    return true;
  };

  var getMousePosition = function getMousePosition(event) {
    var x = event.clientX - overlayFullscreen.clientWidth / 2;
    var y = event.clientY - overlayFullscreen.clientHeight / 2;

    return {
      x: x,
      y: y
    };
  };

  var getCenterPosition = function getCenterPosition(element) {
    var x = element.offsetWidth / 2 - overlayFullscreen.clientWidth / 2;
    var y = overlayFullscreen.clientWidth / 2 * -1;

    return {
      x: x,
      y: y
    };
  };

  var elasticAnimation = function elasticAnimation(xStart, xEnd) {
    runAnimation(xStart, xEnd);
  };

  var translateAnim = function translateAnim(el, xStart, xEnd) {
    console.log(el.node.dataset.easing);
    el.animate({
      ry: xStart
    }, 4000, mina[el.node.dataset.easing], function () {
      el.animate({
        ry: xEnd
      }, 3000, mina[el.node.dataset.easing]);
    });
  };

  var runAnimation = function runAnimation(xStart, xEnd) {
    els.forEach(function (element) {
      translateAnim(element, xStart, xEnd);
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();