'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Accordions = function Accordions() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $accordions = [].slice.call(document.querySelectorAll('[data-accordion]'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($accordions) {
      $accordions.forEach(function (accordion) {
        var $accordionHeader = accordion.querySelector('[data-accordion-key]');
        var $accordionContent = accordion.querySelector('[data-accordion-content]');
        var $accordionText = $accordionContent.querySelector('.accordions-item-text');

        console.log($accordionContent);

        $accordionHeader.addEventListener('click', function (e) {
          e.preventDefault();

          if (accordion.getAttribute('data-accordion-mode') && accordion.getAttribute('data-accordion-mode') === 'single') {
            console.log('toto');
            closeAllAccordions();
          }

          $accordionHeader.classList.toggle('is-active');
          $accordionContent.classList.toggle('is-active');

          if ($accordionText) {
            $accordionText.classList.toggle('is-active');
          }
        });
      });
    }

    return true;
  };

  var closeAllAccordions = function closeAllAccordions() {
    $accordions.forEach(function (accordion) {
      var $accordionHeader = accordion.querySelector('[data-accordion-key]');
      var $accordionContent = accordion.querySelector('[data-accordion-content]');
      var $accordionText = $accordionContent.querySelector('.accordions-item-text');

      $accordionHeader.classList.remove('is-active');
      $accordionContent.classList.remove('is-active');

      if ($accordionText) {
        $accordionText.classList.remove('is-active');
      }
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();