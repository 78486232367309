'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.AnimationOnScroll = function AnimationOnScroll() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var animationBlocks = document.querySelectorAll('[data-scroll-animation]');

    if (animationBlocks) {
      var lastIndexElement = animationBlocks.length - 1;

      if (animationBlocks[lastIndexElement]) {
        animationBlocks[lastIndexElement].style.marginBottom = '-75px';
      }
    }

    // ToDo active animation class on scroll
    window.addEventListener('scroll', function () {
      var nextElementToAnimate = document.querySelector('[data-scroll-animation]:not(.is-animated)');

      if (nextElementToAnimate) {
        if (isScrolledIntoView(nextElementToAnimate)) {
          nextElementToAnimate.classList.add('is-animated');
        }
      }
    });

    return true;
  };

  function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top + 250;
    var elemBottom = rect.bottom;

    return elemTop < window.innerHeight && elemBottom >= 0;
  }

  // Expose public methods & properties
  return {
    init: init
  };
}();