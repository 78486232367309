'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $body = document.querySelector('body');
  var $isGallery = document.querySelector('#main-header.is-gallery');
  var $logoImg = document.querySelector('#main-header .header-logo img');
  var $dropdowns = document.querySelectorAll('[data-header-subnav]');
  var $mobileSublinks = document.querySelectorAll('.navigation-link.has-sublinks');
  var timer = null;

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($dropdowns) {
      $dropdowns.forEach(function (dropdown) {
        var subnav = dropdown.querySelector('.header-sublinks');
        var link = dropdown.querySelector('.header-link');

        link.addEventListener('click', function (e) {
          e.preventDefault();
        });

        link.addEventListener('mouseenter', function () {
          if (timer) {
            clearTimeout(timer);
          }

          subnav.classList.add('is-active');
        });

        link.addEventListener('mouseleave', function () {
          timer = setTimeout(function () {
            subnav.classList.remove('is-active');
          }, 1000);
        });
      });
    }

    if ($mobileSublinks) {
      $mobileSublinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
          e.preventDefault();
          var linkSublinks = link.nextElementSibling;

          link.classList.toggle('is-active');
          linkSublinks.classList.toggle('is-active');
        });
      });
    }

    var $trigger = document.querySelector('.mobile-menu-trigger');
    var $triggerClose = document.querySelector('.navigation-close');

    if ($trigger && $triggerClose) {

      $trigger.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
        $body.classList.toggle('not-scrollable');
      });

      $triggerClose.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
        $body.classList.toggle('not-scrollable');
      });

      // Activate Follow Sticky
      activateMenuFollow();
    };

    return true;
  };

  var activateMenuFollow = function activateMenuFollow() {
    var $nav = document.querySelector('#main-header');
    var $navBtn = $nav.querySelector('.site-btn');
    var buffer = 10;

    var lastScrollTop = 0;

    var st = document.documentElement.scrollTop;

    if (st >= 158) {
      $nav.classList.remove('is-sticky');
      $navBtn.classList.add('is-light');
      switchLogo('light');
    } else {
      $nav.classList.remove('is-sticky');

      if (!$isGallery) {
        $navBtn.classList.add('is-light');
      }

      switchLogo('light');
    }

    window.addEventListener("scroll", function () {

      var st = document.documentElement.scrollTop;

      // console.log(st);

      if (st >= 158) {
        $nav.classList.add('is-sticky');
        $navBtn.classList.remove('is-light');
        switchLogo('dark');
      } else {
        $nav.classList.remove('is-sticky');

        if (!$isGallery) {
          $navBtn.classList.add('is-light');
        }

        switchLogo('light');
      }

      // if (st > lastScrollTop){
      //   // downscroll code
      //   $nav.classList.add( "hide-nav-bar" );
      // } else if( st < ( lastScrollTop - buffer ) ){
      //   // upscroll code
      //   $nav.classList.remove( "hide-nav-bar" );
      // }
      //
      // if(st <= 15){
      //   $nav.classList.remove( "hide-nav-bar" );
      // }

      lastScrollTop = st;
    });
  };

  var switchLogo = function switchLogo(mode) {
    if ($logoImg) {
      var $logoImgDark = $logoImg.getAttribute('logo-dark');
      var $logoImgLight = $logoImg.getAttribute('logo-light');

      switch (mode) {
        case 'dark':
          $logoImg.src = $logoImgDark;
          break;
        case 'light':
          $logoImg.src = $logoImgLight;
          break;
        default:
          $logoImg.src = $logoImgLight;
      }
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();