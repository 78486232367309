'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.OfferModal = function OfferModal() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $closeOffers = [].slice.call(document.querySelectorAll('[action-close-offer]'));
    var $openOfferButton = [].slice.call(document.querySelectorAll('[action-open-offer]'));

    $openOfferButton.forEach(function ($openBtn) {
      $openBtn.addEventListener('click', function (e) {
        e.preventDefault();
        var currentPopupIndex = $openBtn.dataset.index;

        document.querySelector('.offer-popup-' + currentPopupIndex).classList.add('is-visible');
      });
    });

    $closeOffers.forEach(function ($closeBtn) {
      console.log('has closes');

      $closeBtn.addEventListener('click', function (e) {
        console.log('close clicked');
        e.preventDefault();
        var currentPopupIndex = $closeBtn.dataset.index;
        document.querySelector('.offer-popup-' + currentPopupIndex).classList.remove('is-visible');
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();