'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Gallery = function Gallery() {
  /**
   * jQuery elements
   * @private
   */
  var $body = document.querySelector('body');
  var swiper = null;
  var swiperMobile = null;
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $galleries = [].slice.call(document.querySelectorAll('[data-gallery]'));

    if ($galleries) {
      $galleries.forEach(function (slider) {
        toggleScrollOnBody();
        setGallerySlider();

        window.addEventListener('resize', toggleScrollOnBody);
      });
    }

    return true;
  };

  var setGallerySlider = function setGallerySlider() {
    swiper = new Swiper('.swiper-container.for-desktop', {
      loop: true,

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });

    swiperMobile = new Swiper('.swiper-container.for-mobile', {
      direction: 'vertical',
      loop: true,
      mousewheel: true,

      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  };

  var toggleScrollOnBody = function toggleScrollOnBody() {
    if (window.innerWidth <= 767) {
      $body.classList.add('not-scrollable');
    } else {
      $body.classList.remove('not-scrollable');
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();