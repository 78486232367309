'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Sliders = function Sliders() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('[data-slider]'));

    if ($sliders) {
      $sliders.forEach(function (slider) {
        setTimeout(function () {
          var mySwiper = new Swiper('.swiper-container', {
            loop: true,

            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          });
        }, 275);
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();