'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Calendar = function Calendar() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $calendars = [].slice.call(document.querySelectorAll('[data-calendar]'));
  var $labelWidth = [].slice.call(document.querySelectorAll('.calendar-label'))[0] ? [].slice.call(document.querySelectorAll('.calendar-label'))[0].clientWidth : null;
  var activitiesElements = [];
  var distanceFirstLeft = null;
  var overlaps = null;
  var activities = [];
  var $marker = null;
  var calendarMinStart = null;
  var calendarMaxEnd = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($calendars) {
      $calendars.forEach(function (calendar) {
        var calendarBody = calendar.querySelector('.calendar-body');
        var isDown = false;
        var startX = void 0;
        var scrollLeft = void 0;

        calendarBody.addEventListener('mousedown', function (e) {
          isDown = true;
          calendarBody.classList.add('is-active');
          startX = e.pageX - calendar.offsetLeft;
          scrollLeft = calendar.scrollLeft;
        });

        calendarBody.addEventListener('mouseleave', function () {
          isDown = false;
          calendarBody.classList.remove('is-active');
        });

        calendarBody.addEventListener('mouseup', function () {
          isDown = false;
          calendarBody.classList.remove('is-active');
        });

        calendarBody.addEventListener('mousemove', function (e) {
          if (!isDown) return;

          e.preventDefault();

          var x = e.pageX - calendar.offsetLeft;
          var walk = (x - startX) * 3;

          calendar.scrollLeft = scrollLeft - walk;
        });

        fetchActivities(calendar);

        $marker = calendar.querySelector('.calendar-marker');

        var markerPosition = mapMarker();

        // calendar.scroll({
        //   top: 0,
        //   left: markerPosition,
        //   behavior: 'smooth',
        // });
      });
    }

    return true;
  };

  var fetchActivities = function fetchActivities(calendarElement) {
    var currentCenter = calendarElement.getAttribute('data-center');
    var eventsType = calendarElement.getAttribute('data-calendar');

    console.log(eventsType);
    var eventsId = calendarElement.getAttribute('data-event');
    var url = '/actions/site-module/default/fetch-activities?center=' + currentCenter + '&eventsType=' + eventsType + '&eventId=' + eventsId;

    calendarMinStart = calendarElement.getAttribute('data-min-start');
    calendarMaxEnd = calendarElement.getAttribute('data-max-end');

    var calendarHoursDuration = moment.duration(moment(calendarMaxEnd).diff(moment(calendarMinStart))).asHours();

    var calendarHoursElement = calendarElement.querySelector('.calendar-hours');
    calendarHoursElement.style.minWidth = $labelWidth * (calendarHoursDuration + 4) + 'px';

    var calendarBodyElement = calendarElement.querySelector('.calendar-body');
    calendarBodyElement.style.minWidth = $labelWidth * (calendarHoursDuration + 4) + 'px';

    fetch(url).then(function (response) {
      return response.json();
    }).then(function (data) {
      var activitiesContainers = [].slice.call(calendarElement.querySelectorAll('.calendar-events'));

      activitiesContainers.forEach(function (container) {
        var activitiesContainer = container;
        activitiesElements = [];

        var activitiesByDays = data.filter(function (item) {
          return moment(item.start).days() === parseInt(container.getAttribute('data-days'), 10);
        });

        activities = sortedActivities(activitiesByDays);

        overlaps = overlapedActivities(activities);

        activities.forEach(function (activity) {
          activitiesElements.push(createActivityItem(activity));
        });

        var activitiesBlocks = createActivitesBlocks(activitiesElements).blocks;

        activitiesBlocks.forEach(function (block, index) {
          var lastBlock = activitiesContainer.lastChild;
          var currentBlock = block;

          activitiesContainer.append(currentBlock);

          if (index === 0) {
            distanceFirstLeft = activitiesContainer.firstChild.getBoundingClientRect().left;
          }

          var distanceLastLeft = activitiesContainer.lastChild.getBoundingClientRect().left;
          var distance = 0;

          if (typeof lastBlock !== 'undefined') {
            distance = distanceLastLeft - distanceFirstLeft;
          } else {
            distance = 0;
          }

          mapBlock(activitiesContainer, distance);

          block.childNodes.forEach(function (activityElement) {
            var firstActivity = activities.filter(function (item) {
              return item.id === block.firstChild.getAttribute('data-id');
            });

            mapActivity(firstActivity[0], activityElement);
          });
        });
      });
    });
  };

  var sortedActivities = function sortedActivities(activities) {
    return activities.sort(function (previous, current) {
      var previousTime = new Date(previous.start).getTime();
      var currentTime = new Date(current.end).getTime();

      if (previousTime < currentTime) {
        return -1;
      }

      if (previousTime === currentTime) {
        return 0;
      }

      return 1;
    });
  };

  var overlapedActivities = function overlapedActivities(activities) {
    return activities.reduce(function (result, current, index, array) {
      if (index === 0) return result;

      var previous = array[index - 1];

      var previousEnd = new Date(previous.end).getTime();
      var currentStart = new Date(current.start).getTime();

      var overlap = previousEnd >= currentStart;

      if (overlap) {
        result.overlap = true;
        result.ranges.push({
          previous: previous,
          current: current
        });
      }

      return result;
    }, { overlap: false, ranges: [] });
  };

  var createActivitesBlocks = function createActivitesBlocks(activities) {
    return activities.reduce(function (result, current) {
      var lastBlock = result.blocks[result.blocks.length - 1];

      if (current.classList.contains('has-overlap') && hasOverlap(lastBlock)) {
        result.blocks[result.blocks.length - 1].append(current);
      } else {
        var blockContainer = createActivityContainer();
        blockContainer.append(current);
        result.blocks.push(blockContainer);
      }

      return result;
    }, { blocks: [] });
  };

  var createActivityContainer = function createActivityContainer() {
    var container = document.createElement('div');
    container.classList.add('events-item-container');

    return container;
  };

  var createActivityItem = function createActivityItem(activity) {
    var item = document.createElement('div');
    var endOfDay = moment().endOf('day');

    var duration = moment.duration(moment(activity.end).diff(moment(activity.start)));
    var hours = duration.asHours();

    item.classList.add('events-item');
    item.style.background = 'rgba(' + activity.color.rgb + ', 0.1)';
    item.style.borderColor = activity.color.hex;
    item.setAttribute('data-id', activity.id);

    if (isOverlap(activity)) {
      item.classList.add('has-overlap');
    }
    console.log(endOfDay);

    item.style.width = hours * $labelWidth + 'px';

    item.innerHTML = '<span class="item-line"\n                            style="background: ' + activity.color.hex + '; border-color: ' + activity.color.hex + ';">\n                      </span>\n                      <span class="item-title">' + activity.title + '</span>';

    // console.log(item);

    return item;
  };

  var isOverlap = function isOverlap(activity) {
    var overlap = false;

    if (overlaps) {
      overlaps.ranges.forEach(function (range) {
        if (range.previous === activity || range.current === activity) {
          overlap = true;
        }
      });
    }

    return overlap;
  };

  var hasOverlap = function hasOverlap(block) {
    var overlap = false;

    var currentActivitiesElements = [].slice.call(block.querySelectorAll('.events-item'));

    currentActivitiesElements.forEach(function (activity) {
      if (activity.classList.contains('has-overlap')) {
        overlap = true;
      }
    });

    return overlap;
  };

  var getTimeObject = function getTimeObject(date) {
    var momentDate = null;

    if (!moment.isMoment(date)) {
      momentDate = moment(date);
    } else {
      momentDate = date;
    }

    return {
      hours: momentDate.hours(),
      minutes: momentDate.minutes()
    };
  };

  var mapBlock = function mapBlock(container, distance) {
    var currentBlock = container.lastChild;
    var firstActivity = currentBlock.firstChild;

    var activity = activities.filter(function (item) {
      return item.id === firstActivity.getAttribute('data-id');
    });

    var activityTimeObject = getTimeObject(activity[0].start);
    var calendarTimeObject = getTimeObject(calendarMinStart);

    var hoursPosition = $labelWidth * (activityTimeObject.hours - calendarTimeObject.hours);
    var minutesPosition = $labelWidth / 60 * activityTimeObject.minutes;

    var position = hoursPosition + minutesPosition;
    var constant = container.firstChild === currentBlock ? 0 : 8;

    currentBlock.style.transform = 'translateX(' + (position - distance + constant) + 'px)';
  };

  var mapActivity = function mapActivity(firstActivity, activityElement) {
    var currentActivity = activityElement;

    var activityObject = activities.filter(function (item) {
      return item.id === currentActivity.getAttribute('data-id');
    });

    // console.log(activities, currentActivity.getAttribute('data-id'));

    var minutes = moment.duration(moment(activityObject[0].start).diff(moment(firstActivity.start))).asMinutes();
    var minutesPosition = $labelWidth / 60 * minutes;

    currentActivity.style.transform = 'translateX(' + minutesPosition + 'px)';
  };

  var mapMarker = function mapMarker() {
    var currentTimeObject = getTimeObject(moment());
    var calendarTimeObject = getTimeObject(calendarMinStart);

    var hoursPosition = $labelWidth * (currentTimeObject.hours - calendarTimeObject.hours);
    var minutesPosition = $labelWidth / 60 * currentTimeObject.minutes;

    var position = hoursPosition + minutesPosition;

    $marker.style.transform = 'translateX(' + (position - 0.5) + 'px)';

    return position;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();